<template>
  <div class="mb-3">
    <div>
      <div class="d-inline-flex align-items-center w-100">
        <span class="main-label">Category</span>

        <div class="ml-auto cursor-pointer">
          <span class="cursor-pointer mr-2" @click="clearAh">
            <u>Clear All</u>
          </span>
          <span @click="show">
            <b-badge class="bg-color-primary" pill>{{
              propsValue.ah_1.length +
              propsValue.ah_2.length +
              propsValue.ah_3.length +
              propsValue.ah_4.length
            }}</b-badge>
            <font-awesome-icon
              size="sm"
              icon="chevron-right"
              class="color-primary ml-2"
            />
          </span>
        </div>
      </div>

      <div class="field">
        <div class="field-value" @click="show">
          <span class="placeholder px-2">{{ displayValue }}</span>
        </div>
        <div class="field-select" @click="show">Select</div>
      </div>
      <div
        class="ah-select-list"
        v-if="
          propsValue.ah_1.length > 0 ||
          propsValue.ah_2.length > 0 ||
          propsValue.ah_3.length > 0 ||
          propsValue.ah_4.length > 0
        "
      >
        <div v-for="ah1 of propsValue.ah_1" :key="'show-lvl-1-' + ah1">
          {{ findName(ah1, 1) }}
          <font-awesome-icon
            size="sm"
            icon="times"
            class="ml-2 cursor-pointer"
            @click="removeAh(ah1, 1)"
          />
        </div>
        <div v-for="ah2 of propsValue.ah_2" :key="'show-lvl-2-' + ah2">
          {{ findName(ah2, 2) }}
          <font-awesome-icon
            size="sm"
            icon="times"
            class="ml-2 cursor-pointer"
            @click="removeAh(ah2, 2)"
          />
        </div>
        <div v-for="ah3 of propsValue.ah_3" :key="'show-lvl-3-' + ah3">
          {{ findName(ah3, 3) }}
          <font-awesome-icon
            size="sm"
            icon="times"
            class="ml-2 cursor-pointer"
            @click="removeAh(ah3, 3)"
          />
        </div>
        <div v-for="ah4 of propsValue.ah_4" :key="'show-lvl-4-' + ah4">
          {{ findName(ah4, 4) }}
          <font-awesome-icon
            size="sm"
            icon="times"
            class="ml-2 cursor-pointer"
            @click="removeAh(ah4, 4)"
          />
        </div>
      </div>
    </div>
    <b-modal v-model="isShow" size="xl" title="Category" hide-footer>
      <InputText
        textFloat=""
        placeholder="Search"
        type="text"
        v-model="search"
        name="ah-search"
        @input="searchAH($event)"
      />
      <b-card header-bg-variant="color-primary" body-class="custom-card-body">
        <template #header>
          <div class="d-flex">
            <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-1"
                v-model="filterType"
                :options="filterTypeOptions"
                :aria-describedby="ariaDescribedby"
                @change="clearAh"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
            <div class="ml-auto cursor-pointer" @click="clearAh">
              <u>Clear All</u>
            </div>
          </div>
        </template>
        <template v-if="search == ''">
          <!-- <pre>{{ value }}</pre> -->
          <template v-for="ah_1 of options.ah_1">
            <div class="ah-list" :key="'lvl-1' + ah_1.id">
              <b-form-checkbox
                :id="ah_1.name + ah_1.id"
                :name="ah_1.name + ah_1.id"
                :value="ah_1.id"
                :checked="value[`ah_1`]"
                :unchecked-value="false"
                @change="inputAh(ah_1.id, 1)"
              >
                {{ ah_1.name }}
              </b-form-checkbox>
              <div class="collapse-icon">
                <font-awesome-icon
                  v-if="ah_1.show"
                  icon="minus"
                  @click="ah_1.show = false"
                  class="color-primary cursor-pointer"
                  size="sm"
                />
                <font-awesome-icon
                  v-else
                  @click="ah_1.show = true"
                  icon="plus"
                  class="cursor-pointer"
                  size="sm"
                />
              </div>
            </div>
            <template v-if="ah_1.show">
              <template
                v-for="ah_2 of options.ah_2.filter(
                  (el) => el.ah_1_id == ah_1.id
                )"
              >
                <div :key="'lvl-2' + ah_2.id" class="ah-list lvl-2">
                  <b-form-checkbox
                    :id="ah_2.name + ah_2.id"
                    :name="ah_2.name + ah_2.id"
                    :value="ah_2.id"
                    :checked="value[`ah_2`]"
                    :unchecked-value="false"
                    @change="inputAh(ah_2.id, 2)"
                  >
                    {{ ah_2.name }}
                  </b-form-checkbox>
                  <div class="collapse-icon">
                    <font-awesome-icon
                      v-if="ah_2.show"
                      icon="minus"
                      @click="ah_2.show = false"
                      class="color-primary cursor-pointer"
                      size="sm"
                    />
                    <font-awesome-icon
                      v-else
                      icon="plus"
                      @click="ah_2.show = true"
                      class="cursor-pointer"
                      size="sm"
                    />
                  </div>
                </div>
                <template v-if="ah_2.show">
                  <template
                    v-for="ah_3 of options.ah_3.filter(
                      (el) => el.ah_2_id == ah_2.id
                    )"
                  >
                    <div
                      :key="'lvl-3' + ah_2.key + ah_3.id"
                      class="ah-list lvl-3"
                    >
                      <b-form-checkbox
                        :id="ah_3.name + ah_3.id"
                        :name="ah_3.name + ah_3.id"
                        :value="ah_3.id"
                        :checked="value[`ah_3`]"
                        :unchecked-value="false"
                        @change="inputAh(ah_3.id, 3)"
                      >
                        {{ ah_3.name }}
                      </b-form-checkbox>
                      <div class="collapse-icon">
                        <font-awesome-icon
                          v-if="ah_3.show"
                          icon="minus"
                          @click="ah_3.show = false"
                          class="color-primary cursor-pointer"
                          size="sm"
                        />
                        <font-awesome-icon
                          v-else
                          @click="ah_3.show = true"
                          icon="plus"
                          class="cursor-pointer"
                          size="sm"
                        />
                      </div>
                    </div>
                    <template v-if="ah_3.show">
                      <template
                        v-for="ah_4 of options.ah_4.filter(
                          (el) => el.ah_3_id == ah_3.id
                        )"
                      >
                        <div
                          :key="
                            'lvl-4-' + ah_3.key + ah_4.name + '-' + ah_4.key
                          "
                          class="ah-list lvl-4"
                        >
                          <b-form-checkbox
                            :id="ah_4.name + ah_4.id"
                            :name="ah_4.name + ah_4.id"
                            :value="ah_4.id"
                            :checked="value[`ah_4`]"
                            :unchecked-value="false"
                            @change="inputAh(ah_4.id, 4)"
                          >
                            {{ ah_4.name }}
                          </b-form-checkbox>
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>
        <template v-else>
          <template v-for="ah_1 of searchOptions.ah_1">
            <div class="ah-list" :key="'lvl-1' + ah_1.id">
              <b-form-checkbox
                :id="ah_1.name + ah_1.id"
                :name="ah_1.name + ah_1.id"
                :value="ah_1.id"
                :checked="value[`ah_1`]"
                :unchecked-value="false"
                @change="inputAh(ah_1.id, 1)"
              >
                {{ ah_1.name }}
              </b-form-checkbox>
              <div class="collapse-icon">
                <font-awesome-icon
                  v-if="ah_1.show"
                  icon="minus"
                  @click="ah_1.show = false"
                  class="color-primary cursor-pointer"
                  size="sm"
                />
                <font-awesome-icon
                  v-else
                  @click="ah_1.show = true"
                  icon="plus"
                  class="cursor-pointer"
                  size="sm"
                />
              </div>
            </div>
            <template v-if="ah_1.show">
              <template
                v-for="ah_2 of searchOptions.ah_2.filter(
                  (el) => el.ah_1_id == ah_1.id
                )"
              >
                <div :key="'lvl-2' + ah_2.id" class="ah-list lvl-2">
                  <b-form-checkbox
                    :id="ah_2.name + ah_2.id"
                    :name="ah_2.name + ah_2.id"
                    :value="ah_2.id"
                    :checked="value[`ah_2`]"
                    :unchecked-value="false"
                    @change="inputAh(ah_2.id, 2)"
                  >
                    {{ ah_2.name }}
                  </b-form-checkbox>
                  <div class="collapse-icon">
                    <font-awesome-icon
                      v-if="ah_2.show"
                      icon="minus"
                      @click="ah_2.show = false"
                      class="color-primary cursor-pointer"
                      size="sm"
                    />
                    <font-awesome-icon
                      v-else
                      icon="plus"
                      @click="ah_2.show = true"
                      class="cursor-pointer"
                      size="sm"
                    />
                  </div>
                </div>
                <template v-if="ah_2.show">
                  <template
                    v-for="ah_3 of searchOptions.ah_3.filter(
                      (el) => el.ah_2_id == ah_2.id
                    )"
                  >
                    <div
                      :key="'lvl-3' + ah_2.key + ah_3.id"
                      class="ah-list lvl-3"
                    >
                      <b-form-checkbox
                        :id="ah_3.name + ah_3.id"
                        :name="ah_3.name + ah_3.id"
                        :value="ah_3.id"
                        :checked="value[`ah_3`]"
                        :unchecked-value="false"
                        @change="inputAh(ah_3.id, 3)"
                      >
                        {{ ah_3.name }}
                      </b-form-checkbox>
                      <div class="collapse-icon">
                        <font-awesome-icon
                          v-if="ah_3.show"
                          icon="minus"
                          @click="ah_3.show = false"
                          class="color-primary cursor-pointer"
                          size="sm"
                        />
                        <font-awesome-icon
                          v-else
                          @click="ah_3.show = true"
                          icon="plus"
                          class="cursor-pointer"
                          size="sm"
                        />
                      </div>
                    </div>
                    <template v-if="ah_3.show">
                      <template
                        v-for="ah_4 of searchOptions.ah_4.filter(
                          (el) => el.ah_3_id == ah_3.id
                        )"
                      >
                        <div
                          :key="
                            'lvl-4-' + ah_3.key + ah_4.name + '-' + ah_4.key
                          "
                          class="ah-list lvl-4"
                        >
                          <b-form-checkbox
                            :id="ah_4.name + ah_4.id"
                            :name="ah_4.name + ah_4.id"
                            :value="ah_4.id"
                            :checked="value[`ah_4`]"
                            :unchecked-value="false"
                            @change="inputAh(ah_4.id, 4)"
                          >
                            {{ ah_4.name }}
                          </b-form-checkbox>
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>
      </b-card>
      <b-row>
        <b-col>
          <b-button size="md" class="btn-cancel" @click="isShow = false"
            >Cancel</b-button
          >
        </b-col>
        <b-col class="text-right">
          <b-button
            id="upload"
            class="main-color btn-save color-btn"
            size="md"
            @click="submit"
            >Select</b-button
          >
        </b-col>
      </b-row></b-modal
    >
  </div>
</template>

<script>
export default {
  props: {
    propsValue: { required: true },
    placeholder: { required: false, default: "Select value." },
  },
  data() {
    return {
      isShow: false,
      options: {
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
      },
      search: "",
      filterTypeOptions: [
        { text: "เลือกรายการทั้งหมดที่อยู่ภายใต้", value: 0 },
        { text: "เลือกรายการนี้เท่านั้น", value: 1 },
      ],
      filterType: 1,
      ahAll: [],

      isAh2Show: false,
      isAh3Show: false,
      isAh4Show: false,
      value: { ah_1: [], ah_2: [], ah_3: [], ah_4: [] },
      searchOptions: {
        ah_1: [],
        ah_2: [],
        ah_3: [],
        ah_4: [],
      },
    };
  },
  computed: {
    displayValue() {
      let sum =
        this.propsValue.ah_1.length +
        this.propsValue.ah_2.length +
        this.propsValue.ah_3.length +
        this.propsValue.ah_4.length;

      return `Category (${sum})`;
    },
  },
  methods: {
    inputAh(val, lvl) {
      if (this.filterType) {
        if (this.value[`ah_${lvl}`].includes(val)) {
          this.value[`ah_${lvl}`].splice(
            this.value[`ah_${lvl}`].indexOf(val),
            1
          );
        } else {
          this.value[`ah_${lvl}`].push(val);
        }
      } else {
        if (this.value[`ah_${lvl}`].includes(val)) {
          if (lvl == 1) {
            this.value[`ah_${lvl}`].splice(
              this.value[`ah_${lvl}`].indexOf(val),
              1
            );
          }

          let ah2 = this.options.ah_2
            .filter((el) => el[`ah_${lvl}_id`] == val)
            .map((el) => el.id);
          let ah3 = this.options.ah_3
            .filter((el) => el[`ah_${lvl}_id`] == val)
            .map((el) => el.id);
          let ah4 = this.options.ah_4
            .filter((el) => el[`ah_${lvl}_id`] == val)
            .map((el) => el.id);

          this.value.ah_2 = this.value.ah_2.filter((el) => !ah2.includes(el));
          this.value.ah_3 = this.value.ah_3.filter((el) => !ah3.includes(el));
          this.value.ah_4 = this.value.ah_4.filter((el) => !ah4.includes(el));
        } else {
          // this.value[`ah_${lvl}`].push(val);
          this.$nextTick(() => {
            for (const ah1 of this.options[`ah_${lvl}`]) {
              if (ah1.id == val) {
                this.value.ah_1.push(ah1.id);
                ah1.show = true;
              }
            }
            for (const ah2 of this.options.ah_2) {
              if (ah2[`ah_${lvl}_id`] == val) {
                this.value.ah_2.push(ah2.id);
                ah2.show = true;
              }
            }
            for (const ah3 of this.options.ah_3) {
              if (ah3[`ah_${lvl}_id`] == val) {
                this.value.ah_3.push(ah3.id);
                ah3.show = true;
              }
            }
            for (const ah4 of this.options.ah_4) {
              if (ah4[`ah_${lvl}_id`] == val) {
                this.value.ah_4.push(ah4.id);
                ah4.show = true;
              }
            }
          });
        }
      }
    },
    show() {
      this.isShow = true;
      this.value = JSON.parse(JSON.stringify(this.propsValue));
      this.getAH();
    },
    hide() {
      this.isShow = false;
    },
    submit() {
      this.value.ah_1 = [...new Set(this.value.ah_1)];
      this.value.ah_2 = [...new Set(this.value.ah_2)];
      this.value.ah_3 = [...new Set(this.value.ah_3)];
      this.value.ah_4 = [...new Set(this.value.ah_4)];
      this.propsValue = JSON.parse(JSON.stringify(this.value));
      this.$emit("select", this.propsValue);
      this.isShow = false;
    },
    async getAH() {
      const res = await this.$report(`/DashBoard/summary/ah_filter`);

      this.ahAll = res.data.detail;
      this.options.ah_1 = this.ahAll.ah_1.map((el) => {
        return { show: false, ...el };
      });
      this.options.ah_2 = this.ahAll.ah_2.map((el) => {
        return { show: false, ...el };
      });
      this.options.ah_3 = this.ahAll.ah_3.map((el) => {
        return { show: false, ...el };
      });
      this.options.ah_4 = this.ahAll.ah_4.map((el) => {
        return { show: false, ...el };
      });
    },
    findName(val, lvl) {
      let name = this.options[`ah_${lvl}`].find((el) => el.id == val);
      return name?.name;
    },
    removeAh(val, lvl) {
      this.value[`ah_${lvl}`].splice(this.value[`ah_${lvl}`].indexOf(val), 1);
      this.propsValue = {
        ...this.propsValue,
        ...JSON.parse(JSON.stringify(this.value)),
      };
      this.$emit("select", this.propsValue);
    },
    clearAh() {
      this.value = { ah_1: [], ah_2: [], ah_3: [], ah_4: [] };
      this.propsValue = { ...this.propsValue, ...this.value };
      this.$emit("select", JSON.parse(JSON.stringify(this.propsValue)));
      // this.isShow = false;
    },
    searchAH(val) {
      let ah1 = this.options.ah_1.filter((el) => el.name.includes(val));

      let ah2 = this.options.ah_2.filter((el) => el.name.includes(val));

      let ah3 = this.options.ah_3.filter((el) => el.name.includes(val));

      let ah4 = this.options.ah_4.filter((el) => el.name.includes(val));

      if (ah1.length == 0) {
        ah1 = this.options.ah_1.filter((el) =>
          ah4.map((el) => el.ah_1_id).includes(el.id)
        );
      }

      if (ah2.length == 0) {
        ah2 = this.options.ah_2.filter((el) =>
          ah4.map((el) => el.ah_2_id).includes(el.id)
        );
      }
      if (ah3.length == 0) {
        ah3 = this.options.ah_3.filter((el) =>
          ah4.map((el) => el.ah_3_id).includes(el.id)
        );
      }

      this.$nextTick(() => {
        this.searchOptions = {
          ah_1: ah1.map((el) => {
            return { ...el, show: true };
          }),
          ah_2: ah2.map((el) => {
            return { ...el, show: true };
          }),
          ah_3: ah3.map((el) => {
            return { ...el, show: true };
          }),
          ah_4: ah4.map((el) => {
            return { ...el, show: true };
          }),
        };
      });
    },
  },
};
</script>

<style lang="scss">
.field {
  width: 100%;
  min-height: 35px;
  padding: 0 6px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  display: inline-flex;
  align-items: center;
  .field-value {
    cursor: pointer;
    width: 95%;
  }
  .field-select {
    width: auto;
    font-size: 12px;
    color: var(--primary-color);
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0.25rem;
  }
  .placeholder {
    color: #adadad;
    display: inline-block;
    // margin-bottom: 6px;
    // padding-top: 2px;
  }
}
.bg-color-primary {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
}
.ah-list {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  border: 1px solid whitesmoke;
  margin-bottom: 0.5rem;
}

.lvl-2 {
  width: 95%;
  margin-left: auto;
}
.lvl-3 {
  width: 90%;
  margin-left: auto;
}
.lvl-4 {
  width: 85%;
  margin-left: auto;
}
.custom-checkbox > label {
  width: 100%;
  display: inline-flex;
  align-items: center;
}
.ah-select-list {
  padding: 1rem;
  margin: 5px 0;
  background-color: var(--primary-color-light);
  border-radius: 0.3rem;
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  > div {
    flex: 0 1 auto;
    background-color: var(--primary-color);
    color: var(--font-primary-color);
    text-align: center;
    padding: 0.25rem 0.5rem;
    font-size: 12px;
  }
}
.collapse-icon {
  margin-left: auto;
  border-radius: 0.2rem;
  border: 1px solid var(--primary-color);
  padding: 0 0.25rem;
}
.collapse-icon:has(svg[data-icon="plus"]) {
  background: var(--primary-color);
  > svg {
    color: var(--font-primary-color);
  }
}
.custom-card-body {
  height: 60vh;
  overflow: auto;
}
</style>
